export const ArrayReference = [
    [
        {contract: '30A',amphere: '30A',mb: '30',},
        {contract: '40A',amphere: '40A',mb: '40',},
        {contract: '50A',amphere: '50A',mb: '50',},
        {contract: '60A',amphere: '60A',mb: '60',},
        {contract: '8KVA',amphere: '40A',mb: '40',}
    ],
    [
        {contract: '10KVA',amphere: '50A',mb: '50',},
        {contract: '12KVA',amphere: '60A',mb: '60',},
        {contract: '15KVA',amphere: '75A',mb: '75',},
        {contract: '18KVA',amphere: '100A',mb: '100',},
        {contract: '19KVA',amphere: '100A',mb: '100',},
    ],
    [
        {tittle:'30A LIST', contract: '20KVA',amphere: '100A',mb: '100',},
        {tittle:'30A LIST', contract: '21KVA',amphere: '100A',mb: '100',},
        {tittle:'30A LIST', contract: '22KVA',amphere: '100A',mb: '100',},
        {tittle:'30A LIST', contract: '23KVA',amphere: '100A',mb: '100',},
        {tittle:'30A LIST', contract: '25KVA',amphere: '100A',mb: '100',}
    ],
]

export const ArrayProposeKansen= [
    [
        {length: '',size: '',mb: '',},
    ],
]

export const nisetaiArrayProposeKansen= [
    [
        {nisetai_length: '',nisetai_size: '',nisetai_mb: '',},
    ],
]

export const ArraymainLine= [
    [
        { 契約容量: '','': '',"サイズ(CVT)": '',数量: '',Value: '',全量: '',アース棒: '',},
    ],
]

export const ArraymainLineInSubcon= [
    [
        { 契約容量: '','': '',"サイズ(CVT)": '',数量: '',全量: '',アース棒: '',},
    ],
]

export const ArrayforsunLight= [
    [
        {'' : '', パワコンの容量: '','サイズ(CVT)': '',"合計": '',	パワコンごとの長さ: '',全長: '',アース棒: '',},
    ],
]
export const ArrayforsunLightinSubcon= [
    [
        {'' : '', パワコンの容量: '','サイズ(CVT)': '',"合計": '',パワコンごとの長さ: '',全長: '',アース棒: '',},
    ],
]

export const Array30AList= [
    [
        {title:'30A LIST',  kairo: '1',   tkva: '0.48',  contract: '30A',   MainBreaker: '30A',},
        {title:'30A LIST',  kairo: '2',   tkva: '0.96',  contract: '30A',   MainBreaker: '30A',},
        {title:'30A LIST',  kairo: '3',   tkva: '1.44',  contract: '30A',   MainBreaker: '30A',},
        {title:'30A LIST',  kairo: '4',   tkva: '1.92',  contract: '30A',   MainBreaker: '30A',},
        {title:'30A LIST',  kairo: '5',   tkva: '2.4',   contract: '30A',   MainBreaker: '30A',},
        {title:'30A LIST',  kairo: '6',   tkva: '2.88',  contract: '30A',   MainBreaker: '30A',},
        {title:'30A LIST',  kairo: '7',   tkva: '3.36',  contract: '40A',   MainBreaker: '30A',},
        {title:'30A LIST',  kairo: '8',   tkva: '3.84',  contract: '40A',   MainBreaker: '30A',},
        {title:'30A LIST',  kairo: '9',   tkva: '4.32',  contract: '50A',   MainBreaker: '30A',},
        {title:'30A LIST',  kairo: '10',  tkva: '4.8',   contract: '50A',   MainBreaker: '30A',},
        {title:'30A LIST',  kairo: '11',  tkva: '5.28',  contract: '60A',   MainBreaker: '30A',},
        {title:'30A LIST',  kairo: '12',  tkva: '5.76',  contract: '60A',   MainBreaker: '30A',},
    ],
]
export const Array40Ato50AList= [
    [
        {title:'40A LIST',  kairo: '13', tkva: '6.24',   contract: '8KVA',    MainBreaker: '40A',},
        {title:'40A LIST',  kairo: '14', tkva: '6.72',   contract: '8KVA',    MainBreaker: '40A',},
        {title:'40A LIST',  kairo: '15', tkva: '7.2',    contract: '8KVA',    MainBreaker: '40A',},
        {title:'40A LIST',  kairo: '16', tkva: '7.68',   contract: '8KVA',    MainBreaker: '40A',},
    ],

    [
        {title:'50A LIST',  kairo: '17', tkva: '8.16',   contract: '10KVA',    MainBreaker: '50A',},
        {title:'50A LIST',  kairo: '18', tkva: '8.64',   contract: '10KVA',    MainBreaker: '50A',},
        {title:'50A LIST',  kairo: '19', tkva: '9.12',   contract: '10KVA',    MainBreaker: '50A',},
        {title:'50A LIST',  kairo: '20', tkva: '9.6',    contract: '10KVA',    MainBreaker: '50A',},
    ],
]

export const Array60Ato75AList= [
    [
        {title:'60A LIST',  kairo: '21', tkva: '10.08',   contract: '12KVA',    MainBreaker: '60A',},
        {title:'60A LIST',  kairo: '22', tkva: '10.56',   contract: '12KVA',    MainBreaker: '60A',},
        {title:'60A LIST',  kairo: '23', tkva: '11.04',   contract: '12KVA',    MainBreaker: '60A',},
        {title:'60A LIST',  kairo: '24', tkva: '11.52',   contract: '12KVA',    MainBreaker: '60A',},
        {title:'60A LIST',  kairo: '25', tkva: '12',      contract: '12KVA',    MainBreaker: '60A',},
        {title:'60A LIST',  kairo: '26', tkva: '12.48',   contract: '12KVA',    MainBreaker: '60A',},
        {title:'60A LIST',  kairo: '27', tkva: '12.96',   contract: '12KVA',    MainBreaker: '60A',},
    ],

    [
        {title:'75A LIST',  kairo: '28', tkva: '13.44',   contract: '15KVA',    MainBreaker: '75A',},
        {title:'75A LIST',  kairo: '29', tkva: '13.92',   contract: '15KVA',    MainBreaker: '75A',},
        {title:'75A LIST',  kairo: '30', tkva: '14.4',    contract: '15KVA',    MainBreaker: '75A',},
        {title:'75A LIST',  kairo: '31', tkva: '14.88',   contract: '15KVA',    MainBreaker: '75A',},
        {title:'75A LIST',  kairo: '32', tkva: '15.36',   contract: '15KVA',    MainBreaker: '75A',},
        {title:'75A LIST',  kairo: '33', tkva: '15.84',   contract: '15KVA',    MainBreaker: '75A',},
        {title:'75A LIST',  kairo: '34', tkva: '16.32',   contract: '15KVA',    MainBreaker: '75A',},
        {title:'75A LIST',  kairo: '35', tkva: '16.8',    contract: '15KVA',    MainBreaker: '75A',},
    ],
]
    export const Array100AList= [
    [
        {title:'100A LIST', kairo: '36', tkva: '17.28',   contract: '18KVA',    MainBreaker: '100A',},
        {title:'100A LIST', kairo: '37', tkva: '17.76',   contract: '18KVA',    MainBreaker: '100A',},
        {title:'100A LIST', kairo: '38', tkva: '18.24',   contract: '19KVA',    MainBreaker: '100A',},
        {title:'100A LIST', kairo: '39', tkva: '18.72',   contract: '19KVA',    MainBreaker: '100A',},
        {title:'100A LIST', kairo: '40', tkva: '19.2',    contract: '20KVA',    MainBreaker: '100A',},
        {title:'100A LIST', kairo: '41', tkva: '19.68',   contract: '20KVA',    MainBreaker: '100A',},
    ],
]

export const Array120AList= [
    [
        {title:'120A LIST', kairo: '42', tkva: '20.16',   contract: '21KVA',    MainBreaker: '120A',},
        {title:'120A LIST', kairo: '43', tkva: '20.64',   contract: '21KVA',    MainBreaker: '120A',},
        {title:'120A LIST', kairo: '44', tkva: '21.12',   contract: '22KVA',    MainBreaker: '120A',},
        {title:'120A LIST', kairo: '45', tkva: '21.6',    contract: '22KVA',    MainBreaker: '120A',},
        {title:'120A LIST', kairo: '46', tkva: '22.08',   contract: '23KVA',    MainBreaker: '120A',},
        {title:'120A LIST', kairo: '47', tkva: '22.56',   contract: '23KVA',    MainBreaker: '120A',},
        {title:'120A LIST', kairo: '48', tkva: '23.04',   contract: '23KVA',    MainBreaker: '120A',},
        {title:'120A LIST', kairo: '49', tkva: '23.52',   contract: '24KVA',    MainBreaker: '120A',},
    ],
]

export const Array125AList= [
    [
        {title:'125A LIST', kairo: '50', tkva: '24',      contract: '25KVA',    MainBreaker: '125A',},
        {title:'125A LIST', kairo: '-',  tkva: '-',       contract: '30KVA',    MainBreaker: '150A',},
    ],
]


export const ArrayWattsReference = [
    {
        watts : '3.0kW' ,
        data : [
            {
                name : 'ic_cb1',
                details : 'active'
            },
            {
                name : 'size_cb1',
                details : 'active'
            },
            {
                name : 'size_cvt1',
                details : 'details4'
            },
            {
                name : 'total_c1',
                details : 'details1'
            },
            {
                name : 'lpi_c1',
                details : 'details2'
            },
            {
                name : 'fl_c1',
                details : 'details3'
            }
            
        ],
        data3 : 'ic_c2'
    },
    {
        watts : '4.0kW' ,
        data : [
            {
                name : 'ic_c1r2',
                details : 'active'
            },
            {
                name : 'size_c3r2',
                details : 'active'
            },
            {
                name : 'size_c4r2',
                details : 'details4'
            },
            {
                name : 'total_5r2',
                details : 'details1'
            },
            {
                name : 'lpi_6r2',
                details : 'details2'
            },
            {
                name : 'fl_c7r2',
                details : 'details3'
            },
            
        ],
        data3 : 'ic_c2r2'
    },
    {
        watts : '5.5kW' ,
        data : [
            {
                name : 'ic_c1r3',
                details : 'active'
            },
            {
                name : 'size_c3r3',
                details : 'active'
            },
            {
                name : 'size_c4r3',
                details : 'details4'
            },
            {
                name : 'total_5r3',
                details : 'details1'
            },
            {
                name : 'lpi_6r3',
                details : 'details2'
            },
            {
                name : 'fl_c7r3',
                details : 'details3'
            },
            
        ],
        data3 : 'ic_c2r3'
    },
    {
        watts : '5.9kW' ,
        data : [
            {
                name : 'ic_c1r4',
                details : 'active'
            },
            {
                name : 'size_c3r4',
                details : 'active'
            },
            {
                name : 'sizeCV_c4r4',
                details : 'details4'
            },
            {
                name : 'total_5r4',
                details : 'details1'
            },
            {
                name : 'lpi_6r4',
                details : 'details2'
            },
            {
                name : 'fl_c7r4',
                details : 'details3'
            },
            
        ],
        data3 : 'ic_c2r4'
    },
    {
        watts : '8.0kW' ,
        data : [
            {
                name : 'ic_c1r5',
                details : 'active'
            },
            {
                name : 'size_c3r5',
                details : 'active'
            },
            {
                name : 'size_c4r5',
                details : 'details4'
            },
            {
                name : 'total_5r5',
                details : 'details1'
            },
            {
                name : 'lpi_6r5',
                details : 'details2'
            },
            {
                name : 'fl_c7r5',
                details : 'details3'
            },
            
        ],
        data3 : 'ic_c2r5'
    },
    {
        watts : '9.9kW' ,
        data : [
            {
                name : 'ic_c1r6',
                details : 'active'
            },
            {
                name : 'size_c3r6',
                details : 'active'
            },
            {
                name : 'size_c4r6',
                details : 'details4'
            },
            {
                name : 'total_5r6',
                details : 'details1'
            },
            {
                name : 'lpi_6r6',
                details : 'details2'
            },
            {
                name : 'fl_c7r6',
                details : 'details3'
            },
            
        ],
        data3 : 'ic_c2r6'
    },




    // {
    //     watts : '3.0kW' ,
    //     data : ['ic_cb1' , 'size_cb1' , 'size_cvt1' , 'total_c1' , 'lpi_c1' , 'fl_c1'],
    //     data2 : 'ic_c2'
    // },
    // {
    //     watts : '4.0kW' ,
    //     data : ['ic_c1r2' , 'size_c3r2' , 'size_c4r2' , 'total_5r2' , 'lpi_6r2' ,'fl_c7r2'],
    //     data2 : 'ic_c2r2'
    // },
    // {
    //     watts : '5.5kW' ,
    //     data : ['ic_c1r3' , 'size_c3r3' , 'size_c4r3' , 'total_5r3' , 'lpi_6r3' ,'fl_c7r3'],
    //     data2 : 'ic_c2r3'
    // },
    // {
    //     watts : '5.9kW' ,
    //     data : ['ic_c1r4' , 'size_c3r4' , 'sizeCV_c4r4' , 'total_5r4' , 'lpi_6r4' ,'fl_c7r4'],
    //     data2 : 'ic_c2r4'
    // },
    // {
    //     watts : '8.0kW' ,
    //     data : ['ic_c1r5' , 'size_c3r5' , 'size_c4r5' , 'total_5r5' , 'lpi_6r5' ,'fl_c7r5'],
    //     data2 : 'ic_c2r5'
    // },
    // {
    //     watts : '9.9kW' ,
    //     data : ['ic_c1r6' , 'size_c3r6' , 'size_c4r6' , 'total_5r6' , 'lpi_6r6' ,'fl_c7r6'],
    //     data2 : 'ic_c2r6'
    // },


]

// for(let watts of ArrayWattsReference){

//     let data = watts.data
//     let data2 = watts.data2

//     for(let val of data ){
//         this.edit[data2] =  res.MeterInfo[data2]
//         this.edit[val] = wattsDetailsvalue(
//             res,
//             res.MeterInfo[data2],
//             "active",
//             res.MeterInfo[val]
//         );
//     }

   

// }





